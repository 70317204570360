<template>
    <div>
        <ek-dialog
            placeholder="ابحث عن نقطة البيع المحدد"
            btnText="نقطة بيع جديد"
            :title="'اضافة نقطة البيع'"
            @ok="submit"
            @close="reset"
            @search="setSearch"
            ref="dialog"
        >
            <template #body>
                <validationObserver ref="sellPointForm">
                    <b-form>
                        <ek-input-text
                            name="الاسم والكنبة"
                            label="الاسم والكنية"
                            v-model="sellPointDto.name"
                            placeholder="ادخل الاسم والكنية"
                            :rules="[
                                { type: 'required', message: 'اسم مطلوب' },
                            ]"
                        ></ek-input-text>

                        <ek-input-text
                            name="البريد الالكتروني"
                            label="البريد االالكتروني"
                            placeholder="ادخل  البريد الإلكتروني"
                            v-model="sellPointDto.email"
                        ></ek-input-text>
                        <ek-input-text
                            name="كلمة السر"
                            label="كلمة السر"
                            v-model="sellPointDto.password"
                            placeholder="ادخل  كلمة السر"
                            :rules="[
                                {
                                    type: 'required',
                                    message:
                                        'كلمة المرور يجب ان تكون اطول من 8 محارف',
                                },
                            ]"
                        ></ek-input-text>
                        <ek-input-text
                            type="number"
                            name="رقم الهاتف"
                            label="رقم الهاتف"
                            placeholder="ادخل  رقم الهاتف"
                            v-model="sellPointDto.phoneNumber"
                            :rules="[
                                { type: 'required', message: 'رقم مطلوب' },
                                {
                                    type: 'mobile',
                                    message: 'رقم الهاتف غير صالح',
                                },
                            ]"
                        ></ek-input-text>
                        <ek-input-text
                            type="number"
                            name="رقم الواتساب"
                            label="رقم الواتساب"
                            placeholder="ادخل  رقم الواتساب"
                            v-model="sellPointDto.whatsappPhoneNumber"
                        ></ek-input-text>
                        <ek-date-picker
                            placeholder="ادخل  تاريخ الميلاد"
                            v-model="sellPointDto.dateCreated"
                            name="  تاريخ الميلاد"
                            label="تاريخ الميلاد"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'تاريخ الميلاد مطلوب',
                                },
                            ]"
                        >
                        </ek-date-picker>
                        <ek-input-text
                            placeholder="ادخل  نسبة نقطة البيع"
                            name="نسبة نقطة البيع"
                            label="نسبة نقطة البيع"
                            v-model="sellPointDto.rate"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'نسبة نقطة البيع مطلوبة',
                                },
                            ]"
                        ></ek-input-text>
                        <ek-input-text
                            name="العنوان"
                            placeholder="ادخل  العنوان"
                            label="العنوان"
                            v-model="sellPointDto.address"
                            :rules="[
                                { type: 'required', message: 'العنوان مطلوب' },
                            ]"
                        ></ek-input-text>
                        <ek-input-text
                            type="number"
                            placeholder="ادخل  الحد الاعظمي لنقطة البيع"
                            name="حد الاعظمي للبيع"
                            label="حد الاعظمي للبيع"
                            v-model="sellPointDto.moneyLimit"
                            :rules="[
                                {
                                    type: 'required',
                                    message: 'حد الاعظمي مطلوب',
                                },
                            ]"
                        ></ek-input-text>

                        <b-form-group
                            label="امكانية الحسم"
                            v-slot="{ ariaDescribedby }"
                        >
                            <b-form-radio-group
                                id="radio-slots"
                                :options="options"
                                v-model="sellPointDto.canDiscount"
                                :aria-describedby="ariaDescribedby"
                                name="امكانية الحسم"
                            >
                            </b-form-radio-group>
                        </b-form-group>

                        <ek-input-text
                            v-if="sellPointDto.canDiscount"
                            type="number"
                            placeholder="ادخل  حد الحسم"
                            name="حد الحسم"
                            label="حد الحسم"
                            v-model="sellPointDto.discountLimit"
                            :rules="[
                                { type: 'required', message: 'حد الحسم مطلوب' },
                            ]"
                        ></ek-input-text>

                        <b-form-group label="حالة نقطة البيع">
                            <b-form-radio-group
                                id="radio-isHidden"
                                :options="options1"
                                v-model="sellPointDto.isHidden"
                                name="حالة نقطة البيع"
                            >
                            </b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </validationObserver>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data() {
        return {
            options: [
                { text: "مفعل", value: true },
                { text: "غير مفعل ", value: false },
            ],
            options1: [
                { text: "مخفي", value: true },
                { text: "ظاهر", value: false },
            ],
        };
    },
    computed: {
        ...mapState({
            sellPointDto: (state) => state.sellPoints.sellPointDto,
            rateDto: (state) => state.settings.rateDto,
        }),
        ...mapMutations(["RESET_SELL_POINTS_DTO"]),
    },
    methods: {
        submit() {
            this.$refs["sellPointForm"].validate().then((suc) => {
                if (suc) {
                    this.addSellPoint(this.sellPointDto);
                    this.$refs.dialog.close();
                    this.$store.commit("RESET_SELL_POINTS_DTO");
                }
            });
        },

        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["name", "phoneNumber", "address"],
                query,
            });
        },
        reset() {
            this.$refs.sellPointForm.reset();
            this.$store.commit("RESET_SELL_POINTS_DTO");
        },
        ...mapActions(["addSellPoint"]),
    },
};
</script>

<style></style>
